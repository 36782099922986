import React, { useState } from 'react';
import { get, map, filter } from 'lodash';
import { IonItem, IonButton, IonText, IonRadioGroup, IonRadio, IonButtons, IonIcon, IonAlert } from '@ionic/react';
import styled from 'styled-components';
import { detachPaymentMethod } from 'src/data/accessor/stripe';
import { closeCircle } from 'ionicons/icons';
import { useTheme } from 'src/modules/card/context/theme';
import { Footer, Error, Content } from 'src/common-ui';
import { Loading } from './Loading';
import { logError } from 'src/data/accessor/logError'

const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  overflow: scroll;
`;

const InnerContainer = styled.div`
  padding: 0.6em;
`;

export default function Payment({ setStep, creditCards, setCreditCards, onSubmit, ...props }) {
  const theme = useTheme();
  const [defaultCard, setDefaultCard] = useState(get(creditCards, '0.payment_method_id'));
  const [textLoader, setTextLoader] = useState('Paiement en cours...');
  const [popInPaid, setPopInPaid] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState();

  const submit = async () => {
    try {
      setLoadingSubmit(true);
      if (!defaultCard) {
        return;
      }
      await onSubmit({ paymentMethod: defaultCard });
      setLoadingSubmit(false);
    } catch (e) {
      await logError(e)
      setLoadingSubmit(false);
      setError(e);
    }
  };

  const detachPayment = async ({ payment_method }) => {
    try {
      setLoadingSubmit(true);
      setTextLoader('Suppression en cours...');
      const { data } = await detachPaymentMethod({ payment_method });
      const newCards = filter(creditCards, creditCard => creditCard.payment_method_id !== data.id);
      setCreditCards(newCards);
      setLoadingSubmit(false);
      setTextLoader('Paiement en cours...');
    } catch (e) {
      setLoadingSubmit(false);
      setError(e);
    }
  };

  return (
    <Content fullscreen>
      <IonAlert
        data-cy="modal-paiment"
        isOpen={popInPaid}
        onDidDismiss={() => {
          setPopInPaid(false);
        }}
        header="Valider mon paiment"
        subHeader=""
        message="Vous etes sur le point de payer votre commande"
        buttons={[
          {
            text: 'Oui',
            role: 'validate',
            cssClass: 'secondary accept',
            handler: () => submit(),
          },
          {
            text: 'Retour',
            cssClass: 'secondary refuse',
            handler: () => setPopInPaid(false),
          },
        ]}
      />
      {loadingSubmit && <Loading text={textLoader} />}
      <InnerContainer>
        <IonText color={get(theme, 'color.productTitle')} style={{ textAlign: 'center' }}>
          <h6> Mes cartes </h6>
        </IonText>
      </InnerContainer>
      <ContainerCard>
        <InnerContainer>
          <IonRadioGroup allowEmptySelection={false} onIonChange={v => setDefaultCard(v.detail.value)} name="cardId" value={defaultCard}>
            {map(creditCards, (creditCard, i) => (
              <IonItem
                key={get(creditCard, 'payment_method_id')}
                color={get(theme, 'color.background')}
                style={{
                  alignItems: 'center',
                  fontSize: '0.9em',
                }}
              >
                <img alt="brand" src={`/img/stripe/${creditCard.brand}.svg`} style={{ width: '25px', marginRight: '1em' }} />
                ** {creditCard.last4}
                {' - '}
                {creditCard.name}
                <IonRadio
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                  data-cy={`card-${i}`}
                  mode="md"
                  value={get(creditCard, 'payment_method_id')}
                  slot="end"
                  color={get(theme, 'color.productTitle')}
                />
                <IonButtons slot="end" data-cy={`delete-card-${i}`}>
                  <IonButton onClick={() => detachPayment({ payment_method: get(creditCard, 'payment_method_id') })}>
                    <IonIcon slot="icon-only" icon={closeCircle} />
                  </IonButton>
                </IonButtons>
              </IonItem>
            ))}
          </IonRadioGroup>
        </InnerContainer>
      </ContainerCard>
      <InnerContainer>
        {creditCards.length < 4 ? (
          <IonButton
            data-cy="add-card"
            onClick={() => setStep('new')}
            expand="full"
            fill="clear"
            size="small"
            color={get(theme, 'color.title')}
          >
            Ajouter une carte
          </IonButton>
        ) : (
          <div style={{ textAlign: 'center' }}>4 cartes max</div>
        )}
      </InnerContainer>
      {error && <Error error={error} />}
      {!loadingSubmit && (
        <Footer data-cy="btn-order-paid" title="Payer" type="submit" onClick={submit} displayNumber displayPrice {...props} />
      )}
    </Content>
  );
}
