import firebase from 'firebase';

export const logError = async (error) => {
    const defaultFirestore = firebase.app();

    await defaultFirestore
    .firestore()
    .collection('errors')
    .doc()
    .set({
        error: error?.message,
        errorJSON: JSON.stringify(error),
        created_at: new Date()
    })
};
