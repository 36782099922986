import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IonAlert } from '@ionic/react';
import { CheckoutForm } from './CheckoutForm';
import { Loading } from './Loading';
import { useReadContext } from 'src/modules/card/context';
import { logError } from 'src/data/accessor/logError'
export default function PaidWithNewCard({ onSubmit, ...props }) {
  const { user }: any = useReadContext();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState<any>();
  const [popInSaveCard, setPopInSaveCard] = useState(false);
  const ref = useRef<{ stripe: any; cardNumberElement: any; cardName: any }>();
  const history = useHistory();

  const confirmPaymentWithNewCard = async saveCard => {
    const { stripe, cardNumberElement, cardName } = ref.current;

    try {
      if (!stripe) {
        return;
      }
      setLoadingSubmit(true);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        metadata: {
          name: cardName,
        },
      });
      await onSubmit({ paymentMethod: paymentMethod?.id, saveCard });
      setLoadingSubmit(false);
    } catch (e) {
      await logError(e)
      if (e === 'user_required') {
        setLoadingSubmit(false);
        history.push('/auth');
      }
      if (e === 'create_order_error') {
        setLoadingSubmit(false);
        setError(e);
      }
      setError('Une erreur à été rencontrée');
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <IonAlert
        data-cy="modal-paiment"
        isOpen={popInSaveCard}
        onDidDismiss={() => {
          setPopInSaveCard(false);
        }}
        header="Enregistrer la carte de crédit"
        subHeader=""
        message="Voulez vous que l'on conserve votre carte pour vos prochaines commandes ?"
        buttons={[
          {
            text: 'Oui',
            role: 'validate',
            cssClass: 'secondary accept',
            handler: () => confirmPaymentWithNewCard(true),
          },
          {
            text: 'Non',
            cssClass: 'secondary refuse',
            handler: () => confirmPaymentWithNewCard(false),
          },
        ]}
      />
      {loadingSubmit && <Loading text={'Paiement en cours'} />}
      <CheckoutForm
        {...props}
        onSubmit={async v => {
          ref.current = v;
          if (user) {
            setPopInSaveCard(true);
          } else {
            await confirmPaymentWithNewCard(false);
          }
        }}
        loadingSubmit={loadingSubmit}
        type="submit"
        title={error ? 'Erreur : Réssayer' : 'Payer'}
      />
    </>
  );
}
